import styled from 'styled-components';

export const StyledGroup = styled.div`
	background: ${(props) => props.theme.backgroundGroupColor};
	border-color: ${(props) => props.theme.darkText};
	border-style: dashed;
	border-width: 1px;
	color: ${(props) => props.theme.darkText};
	font-size: 1.2em;
	font-style: italic;
	font-weight: bold;
	margin: 16px 16px;
	text-align: center;
`;

export const StyledBusContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	margin: 8px;
	padding: 8px;
	@media all and (min-width: 1024px) {
		grid-gap: 16px;
		grid-template-columns: 1fr 40ch;
	}
`;

export const StyledTramContainer = styled.div`
	
	margin: 8px;
	padding: 8px;
	@media all and (min-width: 1024px) {
	
	}
`;

export const StyledTramImageContainer = styled.div`
	display: grid;
	column-gap: 1rem;
	row-gap: 1rem;
	grid-template-columns: 1fr;
	margin: 8px 0;
	@media all and (min-width: 1024px) {
		grid-gap: 16px;
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

export const StyledTramName = styled.div`
	text-align: center;
	width: 100%;
	padding: 4px 0 6px 0;
	border: 1px solid;
	border-color: ${(props) => props.theme.darkText};
	font-weight: bolder;
`

export const StyledTramImage = styled.div`
	img {
		border: 1px solid #ccc;
		border-color: ${(props) => props.theme.lightBorderColor};
	}
	@media all and (min-width: 800px) {
		cursor: zoom-in;
	}
	@media all and (min-width: 1024px) {
		max-width: auto;
	}
`;

export const StyledImageContainer = styled.div`
	text-align: center;
	img {
		border: 1px solid #ccc;
		border-color: ${(props) => props.theme.lightBorderColor};
	}
	.name {
		color: ${(props) => props.theme.darkText};
		padding: 8px 0;
	}
	@media all and (min-width: 800px) {
		cursor: zoom-in;
	}
	@media all and (min-width: 1024px) {
		max-width: auto;
	}
`;

export const StyledBusData = styled.div`
	font-size: 0.9em;
	table {
		border-collapse: collapse;
		width: 100%;
	}
	td {
		border: 1px solid #ccc;
		border-color: ${(props) => props.theme.lightBorderColor};
		padding: 8px;
	}
	tr:nth-child(odd) {
		background: #eee;
	}
`;

export const ImageZoom = styled.div`
	align-items: center;
	background-color: black;
	background-color: rgba(0, 0, 0, 0.7);
	bottom: 0;
	cursor: zoom-out;
	display: flex;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	img {
		border: 5px solid white;
		max-width: 800px;
	}
`;
