import {
	ImageZoom,
	StyledBusData,
	StyledGroup,
	StyledTramImage,
	StyledTramImageContainer,
	StyledTramContainer,
	StyledTramName
} from '../../styles/tabor';
import React, { useEffect, useState } from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Layout } from '../../components/Layout';
import { StyledArticle } from '../../styles';
import { StyledH2 } from '../../styles/common';
import { graphql } from 'gatsby';
import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO title="Tabor tramwajowy" description="Tabor tramwajowy." />
);

const isBrowser = () => typeof window !== 'undefined';

const TramwajePage = ({ data }) => {
	const [zoom, setZoom] = useState(false);
	const [image, setImage] = useState(null);
	const [isMobile, setIsMobile] = useState(false);
	let staraGrupa = '';
	const { allTramwajeJson } = data;
	const { edges } = allTramwajeJson;
	useEffect(() => {
		if (isBrowser()) {
			window.addEventListener('scroll', handleScrollEvent);
		}
		return () => {
			if (isBrowser()) {
				window.removeEventListener('scroll', handleScrollEvent);
			}
		};
	});

	const handleScrollEvent = () => {
		setZoom(false);
	};

	useEffect(() => {
		if (isBrowser()) {
			window.addEventListener('resize', handleOnResize);
		}
		return () => {
			if (isBrowser()) {
				window.removeEventListener('resize', handleOnResize);
			}
		};
	}, []);

	const handleOnResize = () => {
		setIsMobile(isBrowser() ? window.innerWidth < 800 : false);
	};

	return (
		<>
			<Layout>
				<StyledArticle>
					<StyledH2 as="h1" color="info">
						Tabor tramwajowy
					</StyledH2>
					{edges.map(({ node }) => {
						const {
							grupa,
							obrazek1,
							obrazek2,
							obrazek3,
							nazwa,
							rok,
							dlugosc,
							szerokosc,
							wysokosc,
							siedzace,
							stojace,
							masa,
							sztuk,
						} = node;
						let czyNowaGrupa = false;
						if (staraGrupa !== grupa) {
							czyNowaGrupa = true;
						}
						staraGrupa = grupa;
						return (
							<div key={`${nazwa} ${rok}`}>
								{czyNowaGrupa ? <StyledGroup>{grupa}</StyledGroup> : null}
								<StyledTramContainer>
								<StyledTramName>{nazwa}</StyledTramName>
									<StyledTramImageContainer>
										{/* obrazek 1 */}
										<StyledTramImage
											onClick={() => {
												if (!isMobile) {
													setZoom(true);
													setImage(getImage(obrazek1.childImageSharp.i1));
												}
											}}
										>
											<GatsbyImage
												image={getImage(obrazek1.childImageSharp.i2)}
												alt=""
											/>
										</StyledTramImage>
										{/* obrazek 2 */}
										<StyledTramImage
											onClick={() => {
												if (!isMobile) {
													setZoom(true);
													setImage(getImage(obrazek2.childImageSharp.i1));
												}
											}}
										>
											<GatsbyImage
												image={getImage(obrazek2.childImageSharp.i2)}
												alt=""
											/>
										</StyledTramImage>
										{/* obrazek 3 */}
										<StyledTramImage
											onClick={() => {
												if (!isMobile) {
													setZoom(true);
													setImage(getImage(obrazek3.childImageSharp.i1));
												}
											}}
										>
											<GatsbyImage
												image={getImage(obrazek3.childImageSharp.i2)}
												alt=""
											/>
										</StyledTramImage>
									</StyledTramImageContainer>
									<StyledBusData>
										<table>
											<tbody>
												<tr>
													<td>Rok produkcji:</td>
													<td>{rok}</td>
												</tr>
												<tr>
													<td>Długość:</td>
													<td>{dlugosc}</td>
												</tr>
												<tr>
													<td>Szerokość:</td>
													<td>{szerokosc}</td>
												</tr>
												<tr>
													<td>Wysokość:</td>
													<td>{wysokosc}</td>
												</tr>
												<tr>
													<td>Il.miejsc&nbsp;siedzacych:</td>
													<td>{siedzace}</td>
												</tr>
												<tr>
													<td>Il.miejsc&nbsp;stojących:</td>
													<td>{stojace}</td>
												</tr>
												<tr>
													<td>Masa:</td>
													<td>{masa}</td>
												</tr>
												<tr>
													<td>Sztuk:</td>
													<td>{sztuk}</td>
												</tr>
											</tbody>
										</table>
									</StyledBusData>
							
							</StyledTramContainer>	</div>
						);
					})}
				</StyledArticle>
			</Layout>
			{zoom ? (
				<ImageZoom onClick={() => setZoom(false)}>
					<GatsbyImage image={image} objectFit="cover" />
				</ImageZoom>
			) : null}
		</>
	);
};

export const pageQuery = graphql`
	query {
		allTramwajeJson {
			edges {
				node {
					grupa
					nazwa
					rok
					dlugosc
					szerokosc
					wysokosc
					siedzace
					stojace
					masa
					sztuk
					obrazek1 {
						childImageSharp {
							i1: gatsbyImageData(layout: FIXED, width: 640)
							i2: gatsbyImageData(layout: FULL_WIDTH, quality: 85)
						}
					}
					obrazek2 {
						childImageSharp {
							i1: gatsbyImageData(layout: FIXED, width: 640)
							i2: gatsbyImageData(layout: FULL_WIDTH, quality: 85)
						}
					}
					obrazek3 {
						childImageSharp {
							i1: gatsbyImageData(layout: FIXED, width: 640)
							i2: gatsbyImageData(layout: FULL_WIDTH, quality: 85)
						}
					}
				}
			}
		}
	}
`;

export default TramwajePage;
